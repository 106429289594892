import styled, { css } from "styled-components";
import { Form, Input, Select } from "antd";
const { TextArea } = Input;
import _PButton from "../../../components/PButton";

export const PButton = styled(_PButton)`
  border-radius: 0px;
  background-color: white;
  padding: 10px !important;
  height: unset;
  box-shadow: none;
  border: 2px solid ${({ theme }) => theme.colors.classyRed};

  span {
    color: ${({ theme }) => theme.colors.classyRed};
    font-weight: bold;
    font-size: 1.25rem;
    font-family: "Roboto Bold"
  }

  ${({ onSurface, theme }) =>
    onSurface &&
    css`
      background-color: transparent;
      border: 1px solid #fff !important;
      span {
        color: #fff;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 1) !important;
        border-color: #fff !important;
      }
    `}
`;

export const FormDiv = styled.div`
  flex: 1 1 0px;
  min-height: fit-content;
  background-color: white;
  padding: 0.5em;
  border: 0.1rem solid black;
  .form {
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    display: block;
    width: 100%;
    padding: 10%;
    position: relative;
  }
`;

export const FormContainer = styled(Form)`
.background-red {
  .ant-form-item-explain {
  color: white;
  font-weight: 500;
  }
}
`;

export const StyledTextArea = styled(TextArea)`
min-width: 0;
  border: 1px solid #000;
  border-radius: 0px;
  padding: 5px 10px;
 `;

export const FormItem = styled(Form.Item)`
  margin-bottom: 10px;
`;

export const StyledInput = styled(Input)`
  min-width: 0;
  border: 1px solid #000;
  border-radius: 0px;
  padding: 5px 10px;

  ${({ $primary, theme }) =>
    $primary &&
    css`
      color: ${theme.colors.darkGray2};
      font-weight: 700;
    `}/* height: 3.2rem; */
`;

export const StyledSelect = styled(Select)`
  min-width: 0;
  border: 1px solid #000;
  border-radius: 0px;
  .ant-select-selector {
    height: 32px !important;
    input {
      
    }
  }
  .ant-select-arrow {
      color: rgba(0, 0, 0, 1) !important;
    }
    .ant-select-selection-placeholder [
      font-size: 2rem !important;
    ]

  ${({ primary, theme }) =>
    primary &&
    css`
      color: ${theme.colors.darkGray2};
      font-weight: 700;
    `}
`;

export const FormHeadingSection = styled.div`
  text-align: center;

  & > h2 {
    font-size: 1.3rem;
    color: #762738;
    font-family: "Montserrat";
    font-weight: bold;
  }
  span {
    text-decoration: underline;
  }
  p {
    font-family: "Montserrat";
    font-size: 1rem;
  }
`;
