import { useState } from "react";
import { customGA4Event, eventFormSubmission } from "../../../services/analytics/ga4CustomEvents";
import { Form } from "antd";
import { withTheme } from "styled-components";
import FormConsultationSmall from "./small";
import FormConsultationLarge from "./large";
import { saveToLocalStorage, setGoogleAdwordsInfo } from "../../../utils/helper";
import useFetch from "../../../hooks/useFetch";
import { useCookies } from "react-cookie";

const FormConsultation = ({ theme, variant, referralCode }) => {
  const [cookie, setCookie] = useCookies(["converted"]);
  const { submit, loading } = useFetch();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const fieldsToIgnore = ["phone", "email", "first_name", "last_name"];

  const handleSubmit = (values) => {
    let url = typeof window !== "undefined" ? window.location.href : "null";
    setGoogleAdwordsInfo(values);
    let apiUrl = process.env.NEXT_PUBLIC_BASE_API_URL + process.env.NEXT_PUBLIC_CLASSIC_STEP_1_ENDPOINT
    submit(apiUrl, "POST", values);
    saveToLocalStorage("choices", values);
    setCookie("converted", "step_1", {
      path: "/",
      maxAge: 86400 * 7 // 7 days
    });
    customGA4Event({ eventName: "Step_1" });
    window.location.href = "/consultation";
  };

  if (variant === "large")
    return (
      <FormConsultationLarge
        handleSubmit={handleSubmit}
        loading={loading}
        form={form}
        theme={theme}
        referralCode={referralCode}
        failed={false}
        showModal={showModal}
        setShowModal={setShowModal}
        fieldsToIgnore={fieldsToIgnore}
      />
    );

  return (
    <FormConsultationSmall
      handleSubmit={handleSubmit}
      loading={loading}
      form={form}
      theme={theme}
      referralCode={referralCode}
      failed={false}
      showModal={showModal}
      setShowModal={setShowModal}
      fieldsToIgnore={fieldsToIgnore}
    />
  );
};

export default withTheme(FormConsultation);
